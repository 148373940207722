import React from "react"
import { graphql, Link  } from "gatsby"
import styled, { ThemeProvider } from "styled-components"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Img from "../components/Elements/Img"
import SectionContainer from "../components/SectionContainer"
import VideoPlayer from "../components/VideoPlayer/index"
import { Container, Row, Col } from "../components/Grid"
import {
  SectionBody,
  AccentHeader,
  PageTitle,
} from "../components/Elements/index"

const WorkLink = styled(props => <Link {...props} />)`
  color: #414141;
  &:active {
    color: #414141;
  }

  &:visited {
    color: #414141;
  }
`

const RevomeMarginWrapper = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
`

const Footer = styled.footer`
  background: #6a115a;
  display: flex;
  height: 120px;
  align-items: center;
`

const RevomeMarginImage = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
`

const RevomeMarginVideo = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
`

const VideoContainerFrame = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .gatsby-image-wrapper {
    margin: 0;
    width: 100%;
  }
  iframe {
    position: absolute;
    z-index: 999;
    bottom: 3%;
    width: 53% !important;
    height: 100% !important;
    margin-left: -1%;
  }
`

const dse = ({ data }) => (
  <Layout>
    <ThemeProvider
      theme={{
        backgroundColor: "#FFFFFF",
        primaryColor: "#414141",
        dividerColor: "#E15323;",
        invert: {
          backgroundColor: "#E15323",
          dividerColor: "#ffffff;",
        },
      }}
    >
      <>
        <Seo title="DSE | PSMA Awareness Campaign" />
        <SectionContainer noBottomMargin>
          <Container>
            <AccentHeader size="large" background="#ffffff">
              <WorkLink to="/" style={{ color: "#E15323" }}>
                  The Work
                </WorkLink>
            </AccentHeader>
            <div style={{ paddingBottom: "30px", color: "#E15323" }}>
            <PageTitle>PSMA Awareness Campaign</PageTitle>
            </div>
            <Row>
              <Col md={4}>                
                <AccentHeader>
                  <div style={{ color: "#E15323" }}>The Problem</div>
                </AccentHeader>                
                <SectionBody size="regular">
                  Patients with prostate cancer are very familiar with their routine PSA
test, but when their disease becomes metastatic, additional biomarker
tests are needed to gain a better understanding of its molecular and
cellular makeup. PSMA has emerged as an important, actionable
biomarker, found in more than 80% of men with prostate cancer. The
challenge was to help patients and caregivers understand the
difference between PSA and PSMA, the tests, and what they say about
an individual's prostate cancer to help inform treatment decisions.<br />
                  <br />                  
                </SectionBody>
              </Col>
              <Col md={4}>
                <AccentHeader></AccentHeader>
                <SectionBody size="regular">
                  <h1 style={{ color: "#E15323" }}>Explaining the important difference between PSA and PSMA</h1>
                </SectionBody>
              </Col>
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer noTopMargin noBottomMargin>
          <RevomeMarginImage>
            <Img fluid={data.image1.childImageSharp.fluid} />
          </RevomeMarginImage>
        </SectionContainer>
        

        <SectionContainer invert noBottomMargin noTopMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "30px", color: "#ffffff" }}>
            <Row>
              <Col md={4}>                
                <AccentHeader>The Idea</AccentHeader>                
                <SectionBody size="regular">
                  Juxtapose the familiar (PSA) with the unfamiliar (PSMA) in a simple and
engaging way. Keeping the design clean and the copy easy to
understand, we created a multichannel digital campaign to explain that
PSA, a common blood test, can indicate when prostate cancer is
progressing, but PSMA, detected through a PSMA PET scan, cannot
only tell that the cancer has progressed, but also show where in the
body it has spread.<br />
                  <br />                  
                </SectionBody>
              </Col>
              <Col md={4}>
                <AccentHeader></AccentHeader>
                <SectionBody size="regular">
                  <h1>A tale of two tests</h1>
                </SectionBody>
              </Col>
            </Row>
          </Container>
        </SectionContainer>
        

        <SectionContainer noBottomMargin noTopMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "60px" }}>
              <div style={{ color: "#4AC9F6" }}><h2>Where we started</h2></div>
              <SectionBody size="regular">
                  Social media posts featuring a short video piqued patient interest and drove website traffic with one important question: "Do you know your PSMA status?"<br />
                  <br />                  
                </SectionBody>
              <RevomeMarginWrapper noTopMargin noBottomMargin>
                <VideoPlayer
                  poster={() => <Img fluid={data.videoThumbnail1.childImageSharp.fluid} />}
                  url="https://player.vimeo.com/video/792671920?h=d0c63532c4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                />
              </RevomeMarginWrapper>
          </Container>
        </SectionContainer>


        <SectionContainer invert noTopMargin noBottomMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "60px", color: "#ffffff" }}>
            <Row>
              <Col md={8}>
                <AccentHeader>                  
                  <h2>The Execution</h2>
                </AccentHeader>
                <SectionBody size="regular">
                  All banners and social media efforts centered on an informative website for patients with metastatic prostate cancer and their caregivers. The site used consistent video and graphic elements to boil down what PSMA is, how it differs from PSA, and what it means for the course of treatment into simple, well-organized vignettes. The site also provided a downloadable brochure and links to other helpful resources.<br/><br/>
                </SectionBody>
              </Col>              
            </Row>

            <RevomeMarginImage>
              <Img fluid={data.image2.childImageSharp.fluid} />
            </RevomeMarginImage>

          </Container>
          <RevomeMarginImage>
            <Img fluid={data.image3.childImageSharp.fluid} />
          </RevomeMarginImage>
        </SectionContainer>  


        <SectionContainer>
          <Container style={{ backgroundColor: "#ffffff", color: "#5C5C5C" }}>
            <Row>
              <Col md={8}>
                <AccentHeader>                  
                  <h2 style={{ color: "#E15323" }}>The Results</h2>
                </AccentHeader>
                <SectionBody size="regular">
                  The launch of the PSMA awareness campaign generated nearly 1.9 million impressions. Raising awareness of the biomarker set the stage for the first PSMA-targeted therapy and encouraged patients to talk with their doctors to learn more about their PSMA status and future treatment options.
                </SectionBody>
              </Col>              
            </Row>
          </Container>
        </SectionContainer>        

        <SectionContainer  noTopMargin noBottomMargin>
          <Container>
            <Row>
              <Col md={8}>
                <hr/>
              </Col>              
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer noTopMargin noBottomMargin>
          <Container style={{ paddingBottom: "60px" }}>
            <Row>
              <Col md={8}>                
                <SectionBody size="small">
                  PET, positron emission tomography; PSA, prostate-specific antigen; PSMA, prostate-specific membrane antigen.
                </SectionBody>
              </Col>              
            </Row>
          </Container>
        </SectionContainer>


      </>
    </ThemeProvider>
  </Layout>
)

export default dse

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "2023/dse/image1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image2: file(relativePath: { eq: "2023/dse/image2.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image3: file(relativePath: { eq: "2023/dse/image3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

   
    videoThumbnail1: file(relativePath: { eq: "2023/dse/thumbnail1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`
